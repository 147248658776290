import React from "react"

import Reason from "./Reason"

export default function WorkReasons() {
  return (
    <div className="lg:w-3/5 h-auto lg:h-screen w-full flex flex-col justify-center items-center my-10 pt-5 px-1">
      <div className="lg:w-full w-3/4 flex flex-col justify-center items-center my-14">
        <h1 className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl text-text-primary font-semibold uppercase mb-10">
          why work with us
        </h1>
        <p className="2xl:text-xl md:text-sm text-sm text-text-primary text-center">
          Everyday we work hard to make life of our clients better and happier
        </p>
      </div>
      <div className="w-full flex h-auto lg:h-8/12 flex-col justify-center items-center px-2">
        <div className="w-full h-full flex lg:flex-row flex-col justify-center items-center">
          <Reason
            title={"reliable"}
            content={
              "Hear no excuses. If we promise you something, we will fulfil it, and that’s a promise."
            }
            file={"reliable"}
          />

          <Reason
            title={"scalable"}
            content={
              "Save time with us and invest the same in scaling up your business "
            }
            file={"scalable"}
          />
        </div>
        <div className="w-full h-full flex lg:flex-row flex-col justify-center items-center">
          <Reason
            title={"cost-effective"}
            content={
              "Best and most comprehensive services to all our clients at a nominal cost"
            }
            file={"cost_effective"}
          />

          <Reason
            title={"tech savvy"}
            content={"We use the best technology and IT infrastructure "}
            file={"tech_savvy"}
          />
        </div>
        <div className="w-full h-full flex lg:flex-row flex-col justify-center items-center">
          <Reason
            title={"qualified team of accountants"}
            content={
              "We have the best professionals to provide services with the utmost professionalism"
            }
            file={"qualified_team_of_accountants"}
          />

          <Reason
            title={"working around the clock"}
            content={"We work when you sleep"}
            file={"working_around_the_clock"}
          />
        </div>
      </div>
    </div>
  )
}
