import React from "react"

function StepContent({ step, title, content }) {
  return (
    <div className="md:mx-8 md:w-1/2 w-full flex flex-col md:justify-start justify-center items-center">
      <div className="w-full flex flex-row md:justify-between justify-center items-center mb-8">
        <div className="md:w-2/5 xl:text-8xl lg:text-7xl text-6xl font-bold text-text-primary">
          <h1>{step};</h1>
        </div>
        <div className="w-3/5 md:visible hidden"></div>
      </div>
      <div className="w-5/6 flex flex-row md:justify-between justify-center items-center">
        <div className="w-1/6 md:visible hidden"></div>
        <div className="w-5/6 flex flex-col justify-center my-2">
          <h1 className="md:text-xl text-lg text-text-primary uppercase font-bold md:text-left text-center mb-4">
            {title}
          </h1>
          <p className="md:text-lg text-text-primary  md:text-left text-center">
            {content}
          </p>
        </div>
      </div>
    </div>
  )
}

function StepIllustration({ file }) {
  return (
    <div className="md:mx-8 w-1/2 flex justify-center items-center px-2 my-2">
      <img src={`images/homePage/process/${file}.svg`} />
    </div>
  )
}
export default function WorkProcessStep({
  step,
  title,
  content,
  file,
  right = false,
}) {
  if (right) {
    return (
      <div className="xl:w-3/4 w-full flex md:flex-row flex-col justify-start items-center lg:p-2 md:px-6 my-4">
        <StepIllustration file={file} />
        <StepContent step={step} title={title} content={content} file={file} />
      </div>
    )
  }

  if (!right) {
    return (
      <>
        <div className="xl:w-3/4 w-full md:flex md:flex-row flex-col justify-start items-center lg:p-2 md:px-6 my-4 md:visible hidden">
          <StepContent
            step={step}
            title={title}
            content={content}
            file={file}
          />
          <StepIllustration file={file} />
        </div>
        <div className="w-full flex md:flex-row flex-col justify-start items-center lg:p-2 md:px-6 my-4 md:hidden visible">
          <StepIllustration file={file} />
          <StepContent
            step={step}
            title={title}
            content={content}
            file={file}
          />
        </div>
      </>
    )
  }
}
