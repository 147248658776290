import React from "react"

import WorkProcessStep from "./WorkProcessStep"

export default function WorkProcess() {
  return (
    <div className="w-full flex flex-col justify-center items-center mt-16 font-helvetica">
      <h1
        id="resources"
        className="underline uppercase 2xl:text-6xl xl:text-5xl md:text-4xl text-3xl text-text-primary font-bold mb-10"
      >
        how we work
      </h1>
      <WorkProcessStep
        step="01"
        title=" Understanding the nature of work"
        content={
          "We at VSN devote the time and energy required to understand the working environment of each of our clients. We endeavour to understand the specific requirements of our clients if any. Once we are fully aware of the nature of work and requirements of our client we allocated the required manpower for the execution of the same."
        }
        file="step1"
      />

      <WorkProcessStep
        step="02"
        title="Setting up a dedicated team"
        right
        content={
          "The team is the essence of any timely execution of the assignment, after the first step, we allocate the dedicated manpower for a particular assignment which interacts with the management and keeps the work up to date."
        }
        file="step2"
      />
      <WorkProcessStep
        step="03"
        title="using cloud software"
        content={
          "The most popular way to get your accounts done is by using cloud-based software. We only require login details and supporting accounting information via our secured email mailing system. "
        }
        file="step3"
      />
      <WorkProcessStep
        step="04"
        title="working and performance review"
        right
        content={
          "Once the work is initiated, we shall review, analyse and flag any potential issues to ensure timely remedial actions and will track Service Level Agreement metrics. We also do meeting with the client’s team for regular updates."
        }
        file="step4"
      />
    </div>
  )
}
