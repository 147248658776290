import React from "react"

import "../styles/global.css"
import Navbar from "../components/Navbar"
import Hero from "../components/HomePage/HeroSection"
import About from "../components/HomePage/AboutSection"
import Services from "../components/HomePage/Services"
import WorkProcess from "../components/HomePage/WorkProcess"
import WorkReasons from "../components/HomePage/WorkReasons"
import ClientTestimonials from "../components/HomePage/Testimonials"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"
import SoftwareProficiencySection from "../components/HomePage/SoftwareProficiencySection"
import VideoSection from "../components/HomePage/VideoSection"
import MobileNavbar from "../components/MobileNavbar"
import Whatsapp from "../components/Whatsapp"

import Seo from "../components/seo"

export default function Home() {
  return (
    <>
      <Seo title="HOME" />
      <Whatsapp />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <Hero />
            <About />
            <div className="divider"></div>
            <VideoSection />
            <div className="divider"></div>
            <Services />
            <div className="divider"></div>
            <SoftwareProficiencySection />
            <div className="divider"></div>
            <WorkProcess />
            <div className="divider"></div>
            <WorkReasons />
            <div className="divider"></div>
            <ClientTestimonials />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <Hero />
          <About />
          <div className="divider"></div>
          <VideoSection />
          <div className="divider"></div>
          <Services />
          <div className="divider"></div>
          <SoftwareProficiencySection />
          <div className="divider"></div>
          <WorkProcess />
          <div className="divider"></div>
          <WorkReasons />
          <div className="divider"></div>
          <ClientTestimonials />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
