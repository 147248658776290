import React from "react"

export default function ServiceCard({
  image,
  title,
  content,
  learnMoreUrl,
  mid = false,
}) {
  return (
    <div className="lg:w-1/4 md:h-full w-full flex justify-center items-center mx-1 md:my-0 md:mt-20 my-10 p-1">
      <div
        className={`lg:w-11/12 md:w-3/5 w-full h-full flex flex-col justify-center items-center rounded-3xl p-1 pb-3 ${
          !mid ? "bg-text-primary" : "border-4 border-text-primary"
        }`}
      >
        <div className="w-full h-1/5 flex justify-center items-center">
          <img
            src={`images/homePage/services/${image}.png`}
            alt="image"
            className="rounded-full lg:w-2/5 md:w-1/3 lg:-translate-y-1/3 md:-translate-y-1/2 w-2/6 -translate-y-1/2"
          />
        </div>
        <div className="w-full h-1/5 flex justify-center items-start text-center p-1 md:mb-0 my-3">
          <h1
            className={`uppercase font-bold xl:text-xl md:text-2xl text-2xl m-1 md:-translate-y-0 -translate-y-full ${
              !mid ? "text-white" : "text-text-primary"
            }`}
          >
            {title}
          </h1>
        </div>
        <div
          className={`md:w-10/12  w-11/12 h-2/5 mt-5 flex justify-center items-center text-center md:text-sm py-1 px-4 my-4 ${
            !mid ? "text-white" : "text-text-primary"
          }`}
        >
          <p className="md:-translate-y-0 -translate-y-1/2">{content}</p>
        </div>
        <div className="w-full h-1/5 flex justify-center items-center m-1 mt-3 my-5">
          <a
            className="btn-svc rounded-3xl  py-2 md:-translate-y-0 -translate-y-1/2"
            href={`/our-services`}
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  )
}
