import React, { useRef } from "react"

function Image({ image }) {
  return (
    <div className="lg:w-1/3 w-1/2 flex justify-center items-center px-1">
      <img
        className="p-1 lg:m-3 lg:mt-8 m-1 mt-4"
        src={`images/homePage/softwareProficiency/${image}.png`}
        alt={image}
      />
    </div>
  )
}

export default function SoftwareProficiencySection() {
  return (
    <div className="lg:w-3/4 w-full flex flex-col justify-center items-center font-helvetica">
      <div className="lg:px-0 px-3 my-6">
        <h1
          id="software-proficiency"
          className="2xl:text-6xl xl:text-5xl md:text-3xl text-2xl text-text-primary uppercase lg:my-12 mt-10 mb-1 text-center font-bold"
        >
          software proficiency
        </h1>
      </div>
      <div className="w-full flex justify-center items-center flex-wrap px-2 py-2">
        <Image image="1" />
        <Image image="2" />
        <Image image="3" />
        <Image image="4" />
        <Image image="5" />
        <Image image="6" />
        <Image image="7" />
        <Image image="9" />
        <Image image="8" />
      </div>
    </div>
  )
}
