import React from "react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function Testimonial({
  author,
  designition,
  content,
  image,
  border = true,
}) {
  const image_ = getImage(image.gatsbyImageData)
  return (
    <div className="lg:w-1/3 w-10/12 h-full flex justify-center items-center">
      <div className="w-full h-full flex flex-col justify-center items-center my-5 px-2 font-helvetica">
        <div
          className={`testimonial-box w-full h-full flex flex-col justify-evenly items-center p-2  rounded-tr-lg rounded-bl-lg rounded-tl-[50px] rounded-br-[50px] pb-10 ${
            !border ? "bg-text-primary" : "border-text-primary border-4"
          }`}
        >
          <div className="w-5/6 h-1/4 flex flex-row justify-between items-center">
            <div>
              <img src="images/homePage/testimonials/quotes.svg" alt="" />
            </div>
            <div></div>
          </div>
          <div className="w-5/6 h-3/4 flex justify-center items-center font-helvetica text-center">
            <p
              className={`text-[18px] text-center ${
                !border ? "text-white" : "text-text-primary"
              }`}
            >
              "{content}"
            </p>
          </div>
        </div>
        <div className="w-full h-60 py-3 flex justify-center flex-col items-center text-center mt-10">
          <div className="my-2 h-4/6">
            <GatsbyImage
              image={image_}
              className="rounded-full aspect-square border-card-border border-2"
            />
          </div>
          <h1 className="h-1/6 uppercase text-lg text-text-primary font-bold my-1">
            {author}
          </h1>
          <div className="h-1/6 w-10/12 flex justify-center items-center">
            <h1 className="text-sm text-text-primary">{designition}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
